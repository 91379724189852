
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['tabs-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="tabs-标签页"><AnchorLink to="#tabs-标签页" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Tabs 标签页</h1>
<h2 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h2>
</div><Previewer {...DUMI_ALL_DEMOS['tabs-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="tabs-api"><AnchorLink to="#tabs-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Tabs API</h3>

























































































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>value</td><td>绑定当前选中标签的标识符</td><td>boolean</td><td><code>0</code></td></tr><tr><td>type</td><td>样式风格类型，可选值为 img</td><td>string</td><td><code>line</code></td></tr><tr><td>swipeable</td><td>是否开启手势滑动切换</td><td>boolean</td><td><code>false</code></td></tr><tr><td>sticky</td><td>是否使用粘性定位布局</td><td>boolean</td><td><code>false</code></td></tr><tr><td>stickyStyle</td><td>粘性组件的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>isChangeColor</td><td>滑动到置顶时是否变成白底</td><td>boolean</td><td>-</td></tr><tr><td>ellipsis</td><td>是否省略过长的标题文字</td><td>boolean</td><td><code>true</code></td></tr><tr><td>disabled</td><td>是否禁用标签</td><td>boolean</td><td><code>false</code></td></tr><tr><td>duration</td><td>动画时间，单位秒</td><td>number</td><td><code>0.3</code></td></tr><tr><td>swipeThreshold</td><td>滚动阈值，标签数量超过阈值且总宽度超过标签栏宽度时开始横向滚动</td><td>number</td><td><code>5</code></td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr><tr><td>onChange</td><td>切换标签的回调函数</td><td>function</td><td><code>(index, item) =&gt; void</code></td></tr><tr><td>onSwitch</td><td>滑动时的回调函数</td><td>function</td><td><code>(index) =&gt; void</code></td></tr></tbody></Table>
<h3 id="tabs-api-1"><AnchorLink to="#tabs-api-1" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Tabs API</h3>



































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>title</td><td>标题</td><td>ReactNode</td><td>-</td></tr><tr><td>selected</td><td>选中时</td><td>boolean</td><td>-</td></tr><tr><td>disabled</td><td>是否禁用标签</td><td>boolean</td><td><code>false</code></td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }