
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['cell-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="cell-列表项"><AnchorLink to="#cell-列表项" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Cell 列表项</h1>
<p>为列表中的单个展示项</p>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
<p>Cell 可以单独使用，也可以与 CellGroup 搭配使用，CellGroup 可以为 Cell 提供上下外边框。</p>
</div><Previewer {...DUMI_ALL_DEMOS['cell-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="cell-api"><AnchorLink to="#cell-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Cell API</h3>



































































































































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>title</td><td>左侧标题</td><td><code>number 、 string</code></td><td>-</td></tr><tr><td>value</td><td>右侧内容</td><td><code>number 、 string 、ReactNode</code></td><td>-</td></tr><tr><td>label</td><td>标题下方的描述信息</td><td>string</td><td>-</td></tr><tr><td>desc</td><td>内容下方的描述信息</td><td>string</td><td>-</td></tr><tr><td>icon</td><td>左侧图标名称</td><td>ReactNode</td><td>-</td></tr><tr><td>center</td><td>是否使内容垂直居中</td><td>boolean</td><td>false</td></tr><tr><td>isLink</td><td>是否显示箭头</td><td>boolean</td><td>false</td></tr><tr><td>required</td><td>是否显示表单必填星号</td><td>boolean</td><td>false</td></tr><tr><td>border</td><td>是否显示内边框</td><td>boolean</td><td>true</td></tr><tr><td>titleClass</td><td>title 的 class</td><td>string</td><td>-</td></tr><tr><td>titleStyle</td><td>title 的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>valueClass</td><td>value 的 class</td><td>string</td><td>-</td></tr><tr><td>valueStyle</td><td>value 的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>labelClass</td><td>label 的 class</td><td>string</td><td>-</td></tr><tr><td>labelStyle</td><td>label 的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>descClass</td><td>desc 的 class</td><td>string</td><td>-</td></tr><tr><td>descStyle</td><td>desc 的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>linkClass</td><td>link 的 class</td><td>string</td><td>-</td></tr><tr><td>linkStyle</td><td>link  的样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>onClick</td><td>点击单元格后的回调</td><td>function</td><td>-</td></tr></tbody></Table>
<h3 id="cellgroup-api"><AnchorLink to="#cellgroup-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>CellGroup API</h3>

















<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>shadow</td><td>是否显示外阴影</td><td>boolean</td><td>true</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }