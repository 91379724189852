// @ts-nocheck
import React from 'react';
import { dynamic } from 'dumi';
import rawCode1 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/BetterScroll/demo/index.tsx?dumi-raw-code';
import rawCode2 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/BetterScroll/demo/index.scss?dumi-raw-code';
import rawCode3 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Button/demo/index.tsx?dumi-raw-code';
import rawCode4 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Button/demo/index.scss?dumi-raw-code';
import rawCode5 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Cell/demo/index.tsx?dumi-raw-code';
import rawCode6 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/ConfigProvider/demo/index.tsx?dumi-raw-code';
import rawCode7 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/ConnectFooter/demo/index.tsx?dumi-raw-code';
import rawCode8 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Divider/demo/index.tsx?dumi-raw-code';
import rawCode9 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/FloatingAd/demo/index.tsx?dumi-raw-code';
import rawCode10 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/FloatingAd/demo/index.scss?dumi-raw-code';
import rawCode11 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Icon/demo/index.tsx?dumi-raw-code';
import rawCode12 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Image/demo/index.tsx?dumi-raw-code';
import rawCode13 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/IndexBar/demo/index.tsx?dumi-raw-code';
import rawCode14 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Input/demo/index.tsx?dumi-raw-code';
import rawCode15 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/KeyBoard/demo/index.tsx?dumi-raw-code';
import rawCode16 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/KeyBoard/demo/data.ts?dumi-raw-code';
import rawCode17 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/KeyBoard/demo/index.scss?dumi-raw-code';
import rawCode18 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Layout/demo/index.tsx?dumi-raw-code';
import rawCode19 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Layout/demo/index.scss?dumi-raw-code';
import rawCode20 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Loading/demo/index.tsx?dumi-raw-code';
import rawCode21 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Modal/demo/index.tsx?dumi-raw-code';
import rawCode22 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/NoticeBar/demo/index.tsx?dumi-raw-code';
import rawCode23 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Orientation/demo/index.tsx?dumi-raw-code';
import rawCode24 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Overlay/demo/index.tsx?dumi-raw-code';
import rawCode25 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/PageLoading/demo/index.tsx?dumi-raw-code';
import rawCode26 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Panel/demo/index.tsx?dumi-raw-code';
import rawCode27 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/PasswordInput/demo/index.tsx?dumi-raw-code';
import rawCode28 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/PasswordInput/demo/index.scss?dumi-raw-code';
import rawCode29 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Picker/demo/index.tsx?dumi-raw-code';
import rawCode30 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Picker/demo/data.ts?dumi-raw-code';
import rawCode31 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Popup/demo/index.tsx?dumi-raw-code';
import rawCode32 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/PopupMiddle/demo/index.tsx?dumi-raw-code';
import rawCode33 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/PopupMiddle/demo/index.scss?dumi-raw-code';
import rawCode34 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Result/demo/index.tsx?dumi-raw-code';
import rawCode35 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/SortBar/demo/index.tsx?dumi-raw-code';
import rawCode36 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Space/demo/index.tsx?dumi-raw-code';
import rawCode37 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Sticky/demo/index.tsx?dumi-raw-code';
import rawCode38 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Sticky/demo/index.scss?dumi-raw-code';
import rawCode39 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Style/demo/index.tsx?dumi-raw-code';
import rawCode40 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Style/demo/index.scss?dumi-raw-code';
import rawCode41 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Swiper/demo/index.tsx?dumi-raw-code';
import rawCode42 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Swiper/demo/index.scss?dumi-raw-code';
import rawCode43 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Switch/demo/index.tsx?dumi-raw-code';
import rawCode44 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/TabBar/demo/index.tsx?dumi-raw-code';
import rawCode45 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Tabs/demo/index.tsx?dumi-raw-code';
import rawCode46 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Tabs/demo/index.scss?dumi-raw-code';
import rawCode47 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Tag/demo/index.tsx?dumi-raw-code';
import rawCode48 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Timeline/demo/index.tsx?dumi-raw-code';
import rawCode49 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Toast/demo/index.tsx?dumi-raw-code';
import rawCode50 from '!!dumi-raw-code-loader!/home/johan/jingqb/jingui/src/components/Typography/demo/index.tsx?dumi-raw-code';

export default {
  'betterscroll-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/BetterScroll/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode1},"index.scss":{"import":"./index.scss","content":rawCode2}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"BetterScroll","identifier":"betterscroll-demo"},
  },
  'button-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Button/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode3},"index.scss":{"import":"./index.scss","content":rawCode4}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Button","identifier":"button-demo"},
  },
  'cell-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Cell/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode5}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Cell","identifier":"cell-demo"},
  },
  'configprovider-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/ConfigProvider/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode6}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"ConfigProvider","identifier":"configprovider-demo"},
  },
  'connectfooter-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/ConnectFooter/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode7}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"ConnectFooter","identifier":"connectfooter-demo"},
  },
  'divider-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Divider/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode8}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Divider","identifier":"divider-demo"},
  },
  'floatingad-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/FloatingAd/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode9},"index.scss":{"import":"./index.scss","content":rawCode10}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"FloatingAd","identifier":"floatingad-demo"},
  },
  'icon-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Icon/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode11}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"identifier":"icon-demo"},
  },
  'image-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Image/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode12}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Image","identifier":"image-demo"},
  },
  'indexbar-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/IndexBar/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode13}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"IndexBar","identifier":"indexbar-demo"},
  },
  'input-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Input/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode14}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Input","identifier":"input-demo"},
  },
  'keyboard-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/KeyBoard/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode15},"data.ts":{"import":"./data","content":rawCode16},"index.scss":{"import":"./index.scss","content":rawCode17}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"KeyBoard","identifier":"keyboard-demo"},
  },
  'layout-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Layout/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode18},"index.scss":{"import":"./index.scss","content":rawCode19}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Layout","identifier":"layout-demo"},
  },
  'loading-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Loading/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode20}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Loading","identifier":"loading-demo"},
  },
  'modal-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Modal/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode21}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Modal","identifier":"modal-demo"},
  },
  'noticebar-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/NoticeBar/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode22}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"NoticeBar","identifier":"noticebar-demo"},
  },
  'orientation-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Orientation/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode23}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Orientation","identifier":"orientation-demo"},
  },
  'overlay-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Overlay/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode24}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Overlay","identifier":"overlay-demo"},
  },
  'pageloading-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/PageLoading/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode25}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"PageLoading","identifier":"pageloading-demo"},
  },
  'panel-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Panel/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode26}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Panel","identifier":"panel-demo"},
  },
  'passwordinput-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/PasswordInput/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode27},"index.scss":{"import":"./index.scss","content":rawCode28}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"PasswordInput","identifier":"passwordinput-demo"},
  },
  'picker-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Picker/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode29},"data.ts":{"import":"./data","content":rawCode30}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Picker","identifier":"picker-demo"},
  },
  'popup-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Popup/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode31}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Popup","identifier":"popup-demo"},
  },
  'popupmiddle-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/PopupMiddle/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode32},"index.scss":{"import":"./index.scss","content":rawCode33}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"PopupMiddle","identifier":"popupmiddle-demo"},
  },
  'result-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Result/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode34}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Result","identifier":"result-demo"},
  },
  'sortbar-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/SortBar/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode35}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"SortBar","identifier":"sortbar-demo"},
  },
  'space-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Space/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode36}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Space","identifier":"space-demo"},
  },
  'sticky-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Sticky/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode37},"index.scss":{"import":"./index.scss","content":rawCode38}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Sticky","identifier":"sticky-demo"},
  },
  'style-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Style/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode39},"index.scss":{"import":"./index.scss","content":rawCode40}},"dependencies":{"react":{"version":">=16.6.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-transition-group":{"version":"4.4.5"},"react-dom":{"version":">=16.6.0"}},"identifier":"style-demo"},
  },
  'swiper-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Swiper/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode41},"index.scss":{"import":"./index.scss","content":rawCode42}},"dependencies":{"react":{"version":"18.2.0"},"swiper":{"version":"9.2.4"}},"componentName":"Swiper","identifier":"swiper-demo"},
  },
  'switch-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Switch/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode43}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Switch","identifier":"switch-demo"},
  },
  'tabbar-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/TabBar/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode44}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"TabBar","identifier":"tabbar-demo"},
  },
  'tabs-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Tabs/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode45},"index.scss":{"import":"./index.scss","content":rawCode46}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Tabs","identifier":"tabs-demo"},
  },
  'tag-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Tag/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode47}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Tag","identifier":"tag-demo"},
  },
  'timeline-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Timeline/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode48}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Timeline","identifier":"timeline-demo"},
  },
  'toast-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Toast/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode49}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Toast","identifier":"toast-demo"},
  },
  'typography-demo': {
    component: (require('/home/johan/jingqb/jingui/src/components/Typography/demo/index.tsx')).default,
    previewerProps: {"sources":{"_":{"tsx":rawCode50}},"dependencies":{"react":{"version":">=16.14.0"},"@jojobo/jing-ui":{"version":"0.0.36","css":"@jojobo/jing-ui/dist/jing-ui.min.css"},"react-dom":{"version":">=16.14.0"}},"componentName":"Typography","identifier":"typography-demo"},
  },
};
