
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['picker-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="picker-选择器"><AnchorLink to="#picker-选择器" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Picker 选择器</h1>
<p>提供多个选项集合供用户选择，支持单列选择和多列级联，通常与<Link to="../feedback/popup">弹出层</Link>组件配合使用。</p>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['picker-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>





































































































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>style</td><td>自定义样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>columns</td><td>对象数组，配置每一列显示的数据</td><td>`Column[]``</td><td>[]</td></tr><tr><td>defaultIndex</td><td>单列选择时，默认选中项的索引</td><td>number</td><td>0</td></tr><tr><td>title</td><td>顶部栏标题</td><td>string</td><td>-</td></tr><tr><td>confirmButtonText</td><td>确认按钮文字</td><td>string</td><td><code>确认</code></td></tr><tr><td>cancelButtonText</td><td>取消按钮文字</td><td>string</td><td><code>取消</code></td></tr><tr><td>loading</td><td>是否显示加载状态</td><td>boolean</td><td><code>false</code></td></tr><tr><td>showToolbar</td><td>是否显示顶部栏</td><td>boolean</td><td><code>true</code></td></tr><tr><td>itemHeight</td><td>选项高度</td><td>number</td><td>44</td></tr><tr><td>visibleItemCount</td><td>可见的选项个数</td><td>number</td><td>6</td></tr><tr><td>swipeDuration</td><td>快速滑动时惯性滚动的时长，单位 ms</td><td>number</td><td>1000</td></tr><tr><td>onConfirm</td><td>点击完成按钮时触发</td><td><em>currentValue: PickerOption | PickerOption[], currentIndex: number | number[]</em></td><td>-</td></tr><tr><td>onCancel</td><td>点击取消按钮时触发</td><td><em>currentValue: PickerOption | PickerOption[], currentIndex: number | number[]</em></td><td>-</td></tr><tr><td>onChange</td><td>选项改变时触发</td><td><em>currentValue: PickerOption | PickerOption[], currentIndex: number | number[]</em></td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }