
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['overlay-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="overlay-遮罩层"><AnchorLink to="#overlay-遮罩层" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Overlay 遮罩层</h1>
<p>创建一个遮罩层，用于强调特定的页面元素，并阻止用户进行其他操作。</p>
<p>popup、portal 等组件的基础组件</p>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['overlay-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>

































































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>style</td><td>自定义样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>customStyle</td><td>自定义样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>zIndex</td><td>z-index 层级</td><td><code>number/string</code></td><td>-</td></tr><tr><td>visible</td><td>是否显示</td><td>boolean</td><td>false</td></tr><tr><td>duration</td><td>动画时长，单位毫秒</td><td><code>number/string</code></td><td>300</td></tr><tr><td>lockScroll</td><td>是否锁定背景滚动，锁定时蒙层里的内容也将无法滚动</td><td>boolean</td><td>true</td></tr><tr><td>type</td><td>遮罩层的类型，可选值 transparent, normal</td><td><code>normal / transparent</code></td><td>normal</td></tr><tr><td>onClick</td><td>点击后的回调事件</td><td><code>(e: MouseEvent) =&gt; void</code></td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }