
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      

      return (
        <>
          
          <div className="markdown"><h1 id="组件总览"><AnchorLink to="#组件总览" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>组件总览</h1>
<p>以下列出 Jing-ui 提供的所有组件及工具</p>
<h2 id="写在前面"><AnchorLink to="#写在前面" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>写在前面</h2>
<p>所有的组件都有 className 和 style 属性</p>
<h2 id="基础组件"><AnchorLink to="#基础组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>基础组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/button">Button 按钮</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/button-group">ButtonGroup 按钮组</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/cell">Cell 列表项</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/divider">Divider 分割线</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/icon">Icon 图标</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/image">Image 图片</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/base/overlay">Overlay 遮罩层</Link></li>
</ul>
<h2 id="布局组件"><AnchorLink to="#布局组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>布局组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/layout">Layout 栅栏布局</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/layout/space">Space 空行分隔</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/layout/sticky">Sticky 粘性布局</Link></li>
</ul>
<h2 id="展示组件"><AnchorLink to="#展示组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>展示组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/input">Input 输入</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/key-board">KeyBoard 自定义键盘</Link></li>
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/data/list">List 列表</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/notice-bar">NoticeBar 通知栏</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/panel">Panel 面板</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/password-input">PasswordInput 密码输入框</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/picker">Picker 选择器</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/result">Result 结果</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/switch">Switch 输入</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/tag">Tag 标签</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/data/typography">Typography 富文本</Link></li>
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/data/Radio">radio 单选框</Link></li>
</ul>
<h2 id="反馈组件"><AnchorLink to="#反馈组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>反馈组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/feedback/scroll">Scroll 滚动</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/feedback/loading">Loading 加载</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/feedback/modal">Modal 模态框</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/feedback/page-loading">Pageloading 页面加载</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/feedback/popup">Popup 弹出框</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/feedback/toast">Toast 提示框</Link></li>
</ul>
<h2 id="导航组件"><AnchorLink to="#导航组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>导航组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/navigation/index-bar">IndexBar 索引栏</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/navigation/tabbar">TabBar 标签栏</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/navigation/tab">Tab 标签页</Link></li>
</ul>
<h2 id="业务组件"><AnchorLink to="#业务组件" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>业务组件</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/work/area">Area 地址</Link></li>
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/work/orientation">Orientation 方向</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/work/sort-bar">SortBar 排序栏</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/work/timeline">Timeline 时间线</Link></li>
<li className="task-list-item"><input type="checkbox" defaultChecked={true} disabled={true} /> <Link to="./components/work/floatingad">Floatingad 悬浮广告</Link></li>
</ul>
<h2 id="其他"><AnchorLink to="#其他" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>其他</h2>
<ul className="contains-task-list">
<li className="task-list-item"><input type="checkbox" disabled={true} /> <Link to="./components/config/config-provider">configprovider 全局配置</Link></li>
</ul></div>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }