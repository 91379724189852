
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      

      return (
        <>
          
          <div className="markdown"><h2 id="浏览器支持"><AnchorLink to="#浏览器支持" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>浏览器支持</h2>
<ul>
<li>iOS &gt;= 8.0</li>
<li>Android &gt;= 4.0</li>
</ul>
<h2 id="反馈与共建"><AnchorLink to="#反馈与共建" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>反馈与共建</h2>













<Table><thead><tr><th align="left">问题上报</th><th align="left">微信群</th></tr></thead><tbody><tr><td align="left"><Link to="https://github.com/johanazhu/jingui/issues">github issues</Link></td><td align="left"><img src="https://i.loli.net/2021/07/07/7rCSxhHbQoJsqNF.png" width={80} /> <br />备注 "jingui" 加好友后邀请进群</td></tr></tbody></Table></div>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }