
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['pageloading-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="pageloading-页面加载"><AnchorLink to="#pageloading-页面加载" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>PageLoading 页面加载</h1>
<p>页面懒加载时的页面级加载动画</p>
</div><Previewer {...DUMI_ALL_DEMOS['pageloading-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id=""><AnchorLink to="#" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink></h3>
<p>未完成，还需要改造</p>
<h2 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h2>



































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>type</td><td>类型，可选值为 spinner</td><td>string</td><td>circular</td></tr><tr><td>color</td><td>颜色</td><td>string</td><td>#c9c9c9</td></tr><tr><td>size</td><td>加载图标大小，默认单位为 px</td><td>number、 string</td><td>30px</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }