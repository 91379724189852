
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['tabbar-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="tabbar-标签栏"><AnchorLink to="#tabbar-标签栏" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>TabBar 标签栏</h1>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['tabbar-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="tabbar-api"><AnchorLink to="#tabbar-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>TabBar API</h3>





















































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>activeKey</td><td>当前选中项</td><td><code>number、string</code></td><td>-</td></tr><tr><td>defaultActiveKey</td><td>初始选中项, 默认第一个选中</td><td><code>number、string</code></td><td>0</td></tr><tr><td>fixed</td><td>是否固定在底部</td><td>boolean</td><td>true</td></tr><tr><td>safeAreaInsetBottom</td><td>是否开启底部安全区适配，设置 fixed 时默认开启</td><td>boolean</td><td>-</td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr><tr><td>onChange</td><td>值变化时触发的回调函数</td><td>function</td><td><code>(key, path) =&gt; void</code></td></tr><tr><td>beforeChange</td><td>切换标签前的回调函数，返回 false 可阻止切换，支持返回 Promise</td><td>function</td><td>-</td></tr></tbody></Table>
<h3 id="tabbaritem-api"><AnchorLink to="#tabbaritem-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>TabBar.Item API</h3>





















































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>itemKey</td><td>唯一标识，对应 activeKey，不设置则默认取 index 索引</td><td><code>number、 string</code></td><td>-</td></tr><tr><td>pagePath</td><td>页面路径</td><td>string</td><td>-</td></tr><tr><td>text</td><td>tab 上按钮文字</td><td>string</td><td>-</td></tr><tr><td>icon</td><td>ReactNode</td><td>ReactNode</td><td>-</td></tr><tr><td>selectedIcon</td><td>选中时的图标</td><td>ReactNode</td><td>-</td></tr><tr><td>selected</td><td>是否选中</td><td>boolean</td><td>-</td></tr><tr><td>onChange</td><td>值变化时触发的回调函数</td><td>function</td><td><code>(path) =&gt; void</code></td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }