
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['toast-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="toast-轻提示"><AnchorLink to="#toast-轻提示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Toast 轻提示</h1>
<p>在页面中间弹出黑色半透明提示，用于消息通知、加载提示、操作结果提示等场景。</p>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['toast-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="方法"><AnchorLink to="#方法" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>方法</h3>





















































<Table><thead><tr><th>方法名</th><th>说明</th><th>参数</th><th>返回值</th></tr></thead><tbody><tr><td>Toast</td><td>展示提示</td><td><code>options/message</code></td><td>toast 实例</td></tr><tr><td>Toast.info</td><td>展示文字提示</td><td><code>options/message</code></td><td>toast 实例</td></tr><tr><td>Toast.loading</td><td>展示加载提示</td><td><code>options/message</code></td><td>toast 实例</td></tr><tr><td>Toast.success</td><td>展示成功提示</td><td><code>options/message</code></td><td>toast 实例</td></tr><tr><td>Toast.fail</td><td>展示失败提示</td><td><code>options/message</code></td><td>toast 实例</td></tr><tr><td>Toast.clear</td><td>关闭提示</td><td><code>clearAll: boolean</code></td><td><code>void</code></td></tr><tr><td>Toast.allowMultiple</td><td>允许同时存在多个 Toast</td><td>-</td><td><code>void</code></td></tr></tbody></Table>
<h3 id="属性"><AnchorLink to="#属性" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>属性</h3>































































































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>type</td><td>类型</td><td>string</td><td>'info'</td></tr><tr><td>message</td><td>文本内容</td><td><code>number/string</code></td><td>-</td></tr><tr><td>duration</td><td>展示时长(ms)，值为 0 时，toast 不会消失</td><td><code>number/string</code></td><td>2000</td></tr><tr><td>icon</td><td>自定义图标</td><td><code>string/ReactNode</code></td><td>-</td></tr><tr><td>loadingType</td><td>加载图标类型, 可选值为 spinner</td><td>string</td><td>'circular'</td></tr><tr><td>overlay</td><td>是否显示背景遮罩层</td><td>boolean</td><td>false</td></tr><tr><td>forbidClick</td><td>是否禁止背景点击</td><td>boolean</td><td>false</td></tr><tr><td>closeOnClickOverlay</td><td>是否在点击遮罩层后关闭</td><td>boolean</td><td>-</td></tr><tr><td>closeOnClick</td><td>是否在点击后关闭</td><td>boolean</td><td>-</td></tr><tr><td>onClick</td><td>点击内容后的回调函数</td><td>() =&gt; void</td><td>-</td></tr><tr><td>onClose</td><td>关闭时的回调函数</td><td>() =&gt; void</td><td>-</td></tr><tr><td>onClosed</td><td>关闭后的回调函数</td><td>() =&gt; void</td><td>-</td></tr><tr><td>onOpened</td><td>打开后的回调函数</td><td>() =&gt; void</td><td>-</td></tr><tr><td>mountContainer</td><td>指定挂载的节点</td><td>HTMLElement (() =&gt; HTMLElement))</td><td>document.body</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }