export const customLetterLayout = {
    default: [
        'F1 F2 F3 F4 F5 F6 F7 F8 F9 F10',
        '有 没 有 人 曾 告 诉 你 呢',
        '{shift} 我 很 爱 你 呢 呢 呢 {delete}',
        '{123.*!&} {space} {complete}',
    ],
    shift: [
        '大 威 天 龙 大 罗 法 咒 般 若',
        '诸 佛 般 若 轰 雕 虫 小 技',
        '{shift} 哼 竟 敢 班 门 弄 斧 {delete}',
        '{123.*!&} {space} {complete}',
    ],
    symbol: [
        '1 2 3 4 5 6 7 8 9 0',
        '` ! @ # $ % ^ & *',
        '+ - \\ / [ ] { } {delete}',
        '{ABC} , . € £ ￥ {complete}',
    ],
};

export const customNumberLayout = ['9 8 7', '6 5 4', '3 2 1', 'X 0 {delete}'];
