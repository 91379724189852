
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['style-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="style-内置样式"><AnchorLink to="#style-内置样式" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Style 内置样式</h1>
<h3 id="介绍"><AnchorLink to="#介绍" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>介绍</h3>
<p>JingUI 中默认包含了一些常用样式，可以直接通过 className 的方式使用。</p>
<h3 id="文字省略"><AnchorLink to="#文字省略" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>文字省略</h3>
<p>当文本内容长度超过容器最大宽度时，自动省略多余的文本。</p>
<SourceCode code={"<!-- 最多显示一行 -->\n<div class=\"jing-ellipsis\">这是一段最多显示一行的文字，多余的内容会被省略</div>\n\n<!-- 最多显示两行 -->\n<div class=\"jing-multi-ellipsis--l2\">\n    这是一段最多显示两行的文字，多余的内容会被省略\n</div>\n\n<!-- 最多显示三行 -->\n<div class=\"jing-multi-ellipsis--l3\">\n    这是一段最多显示三行的文字，多余的内容会被省略\n</div>"} lang="html" />
<h3 id="1px-边框"><AnchorLink to="#1px-边框" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>1px 边框</h3>
<p>为元素添加 Retina 屏幕下的 1px 边框（即 hairline），基于伪类 transform 实现。</p>
<SourceCode code={"<!-- 上边框 -->\n<div class=\"jing-hairline--top\"></div>\n\n<!-- 下边框 -->\n<div class=\"jing-hairline--bottom\"></div>\n\n<!-- 左边框 -->\n<div class=\"jing-hairline--left\"></div>\n\n<!-- 右边框 -->\n<div class=\"jing-hairline--right\"></div>\n\n<!-- 上下边框 -->\n<div class=\"jing-hairline--top-bottom\"></div>\n\n<!-- 全边框 -->\n<div class=\"jing-hairline--surround\"></div>"} lang="html" />
<h3 id="安全区"><AnchorLink to="#安全区" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>安全区</h3>
<p>为元素添加安全区适配。</p>
<SourceCode code={"<!-- 顶部安全区 -->\n<div class=\"jing-safe-area-top\"></div>\n\n<!-- 底部安全区 -->\n<div class=\"jing-safe-area-bottom\"></div>"} lang="html" />
<h3 id="动画"><AnchorLink to="#动画" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>动画</h3>
<p>这里通过 <code>react-transition-group</code> 第三方库使用内置的动画</p>
<SourceCode code={"# 安装\nyarn add react-transition-group"} lang="bash" />
<SourceCode code={"import { CSSTransition } from 'react-transition-group';"} lang="js" />
</div><Previewer {...DUMI_ALL_DEMOS['style-demo'].previewerProps}><DumiDemo1 /></Previewer></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }