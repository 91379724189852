
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['keyboard-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="keyboard-自定义键盘"><AnchorLink to="#keyboard-自定义键盘" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Keyboard 自定义键盘</h1>
<p>虚拟数字键盘，可以配合<Link to="./password-input">密码输入框组件</Link>或<AnchorLink to="./input#inputkeyboard-api">自定义的输入框组件</AnchorLink>使用。</p>
<p>自定义字母键盘格式限制为 4 行，一个鸡肋功能</p>
<h2 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h2>
<h3 id="基本用法"><AnchorLink to="#基本用法" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>基本用法</h3>
</div><Previewer {...DUMI_ALL_DEMOS['keyboard-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h2 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h2>























































































































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>style</td><td>自定义样式</td><td>CSSProperties</td><td>-</td></tr><tr><td>layout</td><td>布局</td><td>Array</td><td>'default'</td></tr><tr><td>titleLeft</td><td>自定义标题栏左侧内容</td><td>ReactNode</td><td>-</td></tr><tr><td>title</td><td>键盘标题</td><td>string</td><td>-</td></tr><tr><td>theme</td><td>样式风格，可选值为 custom、number、letter</td><td>string</td><td>-</td></tr><tr><td>visible</td><td>是否显示键盘</td><td>boolean</td><td>-</td></tr><tr><td>closeButtonText</td><td>是否将关闭按钮设置为加载中状态，仅在 theme="custom" 时有效</td><td>boolean</td><td>true</td></tr><tr><td>display</td><td>自定义某一按钮的名字</td><td>string</td><td>-</td></tr><tr><td>value</td><td>当前输入值</td><td>string</td><td>-</td></tr><tr><td>hideOnClickOutside</td><td>是否在点击外部时收起键盘</td><td>boolean</td><td>true</td></tr><tr><td>onHandleClose</td><td>点击关闭按钮时触发</td><td>function</td><td>-</td></tr><tr><td>onHandleBlur</td><td>点击关闭按钮或非键盘区域时触发</td><td>function</td><td>-</td></tr><tr><td>onHandleDelete</td><td>点击删除键时触发</td><td>function</td><td>-</td></tr><tr><td>onHandleValue</td><td>输入值回调</td><td>function</td><td>-</td></tr><tr><td>touchEnd</td><td>放开按钮时的回调</td><td>function</td><td>-</td></tr><tr><td>onHandlePress</td><td>点击按钮时的回调</td><td>function</td><td>-</td></tr><tr><td>onHandleShiftCb</td><td>点击 shift 后的自定义回调</td><td>function</td><td>-</td></tr></tbody></Table>
<h2 id="keyapi"><AnchorLink to="#keyapi" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>KeyAPI</h2>









<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead></Table>
<p>功能点:
长按字母连续处理,连续输出,
长按删除键连续删除
按住滑动放大</p></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }