
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['button-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="button-按钮"><AnchorLink to="#button-按钮" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Button 按钮</h1>
<p>按钮用于触发一个操作，如提交表单。</p>
<p>包裹住 Button 组件，根据子元素的多少，进行 flex 布局
默认填满，也可以保持原有 button 宽度</p>
<h2 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h2>
</div><Previewer {...DUMI_ALL_DEMOS['button-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h2 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h2>



















































































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>type</td><td>类型，可选值为 primary、second-primary、default</td><td>string</td><td>'default'</td></tr><tr><td>plain</td><td>是否为朴素按钮</td><td>boolean</td><td>false</td></tr><tr><td>round</td><td>是否为圆形按钮</td><td>boolean</td><td>false</td></tr><tr><td>size</td><td>设置大小，可选值为 large、normal、small、mini</td><td>string</td><td>normal</td></tr><tr><td>disabled</td><td>是否禁用</td><td>boolean</td><td>false</td></tr><tr><td>loading</td><td>是否加载中状态</td><td>boolean</td><td>false</td></tr><tr><td>block</td><td>是否块级元素</td><td>boolean</td><td>false</td></tr><tr><td>color</td><td>自定义颜色</td><td>string</td><td>-</td></tr><tr><td>text</td><td>按钮文字</td><td>string</td><td>-</td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr><tr><td>onClick</td><td>点击后的回调事件</td><td>() =&gt; void</td><td>-</td></tr></tbody></Table>
<h3 id="buttongroup-api"><AnchorLink to="#buttongroup-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>ButtonGroup API</h3>



































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>className</td><td>自定义 class 名</td><td>string</td><td>-</td></tr><tr><td>type</td><td>类型，可选值为 default、fixed-bottom</td><td>string</td><td>'default'</td></tr><tr><td>size</td><td>设置大小，可选值为 large、normal、small、mini</td><td>string</td><td>'normal'</td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }