
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['sortbar-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="sortbar-排序栏"><AnchorLink to="#sortbar-排序栏" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>SortBar 排序栏</h1>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['sortbar-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="sortbar-api"><AnchorLink to="#sortbar-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>SortBar API</h3>





















































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>activeKey</td><td>当前选中项</td><td>string</td><td>-</td></tr><tr><td>type</td><td>模式，默认模式等分划分，此外 "chan"(禅模式)</td><td>string</td><td>'normal'</td></tr><tr><td>title</td><td>标题</td><td>string</td><td>-</td></tr><tr><td>sticky</td><td>是否使用粘性定位布局</td><td>boolean</td><td>-</td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr><tr><td>onClick</td><td>点击筛选后的回调</td><td><code>() =&gt; void</code></td><td>-</td></tr><tr><td>onChange</td><td>点击后的回调，返回 选中项和点击的状态</td><td><code>(key: string, status: string) =&gt; void;</code></td><td>-</td></tr></tbody></Table>
<h3 id="sortbaritem-api"><AnchorLink to="#sortbaritem-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>SortBarItem API</h3>



































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>title</td><td>标题</td><td>string</td><td>-</td></tr><tr><td>itemKey</td><td>唯一标识，对应 activeKey</td><td>string</td><td>-</td></tr><tr><td>selected</td><td>是否选中</td><td>boolean</td><td>-</td></tr><tr><td>onClick</td><td>点击后的回调，返回 点击的状态</td><td><code>(status: string) =&gt; void</code></td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }