// color
export const WHITE = '#fff';

// border
export const BORDER = 'jing-hairline';
export const BORDER_TOP = `${BORDER}--top`;
export const BORDER_LEFT = `${BORDER}--left`;
export const BORDER_RIGHT = `${BORDER}--right`;
export const BORDER_BOTTOM = `${BORDER}--bottom`;
export const BORDER_SURROUND = `${BORDER}--surround`;
export const BORDER_TOP_BOTTOM = `${BORDER}--top-bottom`;
export const BORDER_UNSET_TOP_BOTTOM = `${BORDER}-unset--top-bottom`;
