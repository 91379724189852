
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['loading-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="loading-加载"><AnchorLink to="#loading-加载" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Loading 加载</h1>
<p>加载图标，用于表示加载中的过渡状态。</p>
</div><Previewer {...DUMI_ALL_DEMOS['loading-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h2 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h2>





















































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>type</td><td>类型，可选值为 spinner</td><td>string</td><td>circular</td></tr><tr><td>color</td><td>颜色</td><td>string</td><td>#c8c8c8</td></tr><tr><td>size</td><td>加载图标大小，默认单位为 px</td><td><code>number/string</code></td><td>30px</td></tr><tr><td>textSize</td><td>文字大小，默认单位为 px</td><td><code>number/ string</code></td><td>14px</td></tr><tr><td>textColor</td><td>文字颜色</td><td>string</td><td>#c8c8c8</td></tr><tr><td>vertical</td><td>是否垂直排列图标和文字内容</td><td>boolean</td><td>false</td></tr><tr><td>children</td><td>组件中的内容</td><td>ReactNode</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }