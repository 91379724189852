
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['betterscroll-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="scroll-滚动条"><AnchorLink to="#scroll-滚动条" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Scroll 滚动条</h1>
</div><Previewer {...DUMI_ALL_DEMOS['betterscroll-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>













































































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>pullUpLoading</td><td>是否显示上拉刷新</td><td>boolean</td><td>false</td></tr><tr><td>showPullDownLoading</td><td>是否显示 下拉刷新 loading</td><td>boolean</td><td>-</td></tr><tr><td>pullingUpStatus</td><td>数据是否展示完毕</td><td>string</td><td>-</td></tr><tr><td>bottom</td><td>滚动组件距离底部的距离</td><td>number</td><td>-</td></tr><tr><td>top</td><td>滚动组件距离顶部的距离</td><td>number</td><td>-</td></tr><tr><td>children</td><td>组件中的内容</td><td>React.ReactNode</td><td>-</td></tr><tr><td>bounceTop</td><td>betterscroll 默认属性配置</td><td>boolean</td><td>true</td></tr><tr><td>bounceBottom</td><td>betterscroll 默认属性配置</td><td>boolean</td><td>true</td></tr><tr><td>pullDownCb</td><td>下拉刷新回调</td><td>function</td><td>-</td></tr><tr><td>pullUpCb</td><td>上拉加载回调</td><td>function</td><td>-</td></tr><tr><td>onScroll</td><td>滚动时触发的回调</td><td>function</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }