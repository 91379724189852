
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['layout-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="layout-栅格布局"><AnchorLink to="#layout-栅格布局" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Layout 栅格布局</h1>
<p>Layout 提供了 Row 和 Col 两个组件来进行行列布局。</p>
<h2 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h2>
<p>Layout 组件提供了 <code>24列栅格</code> ，通过在 <code>Col</code> 上添加 <code>span</code> 属性设置列所占的宽度百分比</p>
<h3 id="基础用法"><AnchorLink to="#基础用法" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>基础用法</h3>
</div><Previewer {...DUMI_ALL_DEMOS['layout-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h2 id="row-api"><AnchorLink to="#row-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Row API</h2>



































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>children</td><td>子项内容</td><td>React.ReactNode</td><td>-</td></tr><tr><td>justify</td><td>主轴对齐方式，可选值为 end center space-around space-between</td><td>string</td><td>start</td></tr><tr><td>align</td><td>交叉轴对齐方式，可选值为 center bottom</td><td>string</td><td>top</td></tr><tr><td>onClick</td><td>点击事件</td><td>event: MouseEvent</td><td>-</td></tr></tbody></Table>
<h2 id="col-api"><AnchorLink to="#col-api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Col API</h2>



































<Table><thead><tr><th>参数</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>children</td><td>子项内容</td><td>React.ReactNode</td><td>-</td></tr><tr><td>span</td><td>列元素宽度</td><td>number</td><td>-</td></tr><tr><td>offset</td><td>列元素偏移距离</td><td>number</td><td>-</td></tr><tr><td>onClick</td><td>点击事件</td><td>event: MouseEvent</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }