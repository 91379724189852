import React from 'react';
import { Orientation } from '@jojobo/jing-ui';
import { DemoBlock } from 'demo';

export default () => (
    <DemoBlock
        title="基本用法"
        padding=""
    // className="demo-jing-overlay"
    >
        <Orientation />
    </DemoBlock>
);
