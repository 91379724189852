
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['passwordinput-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="passwordinput-密码输入框"><AnchorLink to="#passwordinput-密码输入框" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>PasswordInput 密码输入框</h1>
<p>带网格的输入框组件，可以用于输入密码、短信验证码等场景，通常与<Link to="./keyboard">自定义键盘</Link>组件配合使用。</p>
<h2 id="引入"><AnchorLink to="#引入" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>引入</h2>
<SourceCode code={"import { PasswordInput } from '@jojobo/jing-ui';"} lang="js" />
<h2 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h2>
</div><Previewer {...DUMI_ALL_DEMOS['passwordinput-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>

























































































<Table><thead><tr><th>属性</th><th>说明</th><th>类型</th><th>默认值</th></tr></thead><tbody><tr><td>value</td><td>密码值</td><td>string</td><td>-</td></tr><tr><td>length</td><td>密码最大长度</td><td>number</td><td>6</td></tr><tr><td>radius</td><td>圆角大小，默认单位为 px</td><td><code>number/string</code></td><td>-</td></tr><tr><td>mask</td><td>是否隐藏密码内容</td><td>boolean</td><td>true</td></tr><tr><td>focused</td><td>是否已聚焦，聚焦时会显示光标</td><td>boolean</td><td>-</td></tr><tr><td>type</td><td>类型 默认为 text，可选 number</td><td>string</td><td>text</td></tr><tr><td>autoFocus</td><td>是否自动聚焦</td><td>boolean</td><td>false</td></tr><tr><td>gutter</td><td>输入框格子之间的间距，如 20px 2em，默认单位为 px</td><td>number</td><td>-</td></tr><tr><td>mode</td><td>模式，默认输入框输入模式和配合 keyboard 组件模式</td><td>string</td><td>normal</td></tr><tr><td>onChange</td><td>数据改变时触发</td><td>function</td><td>-</td></tr><tr><td>onSubmit</td><td>数据输满时触发</td><td>function</td><td>-</td></tr><tr><td>onFocus</td><td>输入框聚焦时触发</td><td>function</td><td>-</td></tr><tr><td>onBlur</td><td>输入框失焦时触发</td><td>function</td><td>-</td></tr></tbody></Table></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }