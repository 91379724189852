
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      

      return (
        <>
          
          <div className="markdown"><h2 id="特性"><AnchorLink to="#特性" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>特性</h2>
<ul>
<li>📦 开箱即用，将注意力集中在组件开发和文档编写上</li>
<li>📋 丰富的 Markdown 扩展，不止于渲染组件 demo</li>
<li>🏷 基于 TypeScript 类型定义，自动生成组件 API</li>
<li>🎨 主题轻松自定义，还可创建自己的 Markdown 组件</li>
</ul>
<h2 id="快速上手"><AnchorLink to="#快速上手" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>快速上手</h2>
<p>文档需补充</p></div>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }