
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Previewer from 'dumi-theme-mobile/es/builtins/Previewer.js';
import Alert from 'dumi-theme-default/es/builtins/Alert';
import API from 'dumi-theme-default/es/builtins/API';
import Badge from 'dumi-theme-default/es/builtins/Badge';
import Example from 'dumi-theme-default/es/builtins/Example';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode';
import Tree from 'dumi-theme-default/es/builtins/Tree';
import Table from 'dumi-theme-default/es/builtins/Table';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = DUMI_ALL_DEMOS['image-demo'].component

      return (
        <>
          
          <React.Fragment><div className="markdown"><h1 id="image-图片"><AnchorLink to="#image-图片" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>Image 图片</h1>
<p>增强版的 img 标签，提供多种图片填充模式，支持图片懒加载、加载中提示、加载失败提示。</p>
<h3 id="代码演示"><AnchorLink to="#代码演示" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>代码演示</h3>
</div><Previewer {...DUMI_ALL_DEMOS['image-demo'].previewerProps}><DumiDemo1 /></Previewer>
<div className="markdown"><h3 id="api"><AnchorLink to="#api" aria-hidden="true" tabIndex={-1}><span className="icon icon-link" /></AnchorLink>API</h3>
<p>| 属性        | 说明                     | 类型                                        | 默认值  |
| ----------- | ------------------------ | ------------------------------------------- | ------- | ------ | ------------- | ------ | ------ |
| width       | 宽度，默认单位为 px      | <code>number                                     | string</code> | -      |
| height      | 高度，默认单位为 px      | <code>number                                     | string</code> | -      |
| src         | 图片链接                 | string                                      | -       |
| fit         | 图片填充模式，<code>'contain' | 'cover'                                     | 'fill'  | 'none' | 'scale-down'</code> | string | 'fill' |
| alt         | 替代文本                 | string                                      | -       |
| round       | 是否显示为圆形           | boolean                                     | false   |
| radius      | 圆角大小，默认单位为 px  | <code>number                                     | string</code> | -      |
| showLoading | 是否展示图片加载中提示   | boolean                                     | true    |
| showError   | 是否展示图片加载失败提示 | boolean                                     | true    |
| loadingIcon | 加载时提示的自定义图标   | ReactNode                                   | -       |
| errorIcon   | 失败时提示的自定义图标   | ReactNode                                   | -       |
| children    | 组件中的内容             | ReactNode                                   | -       |
| onClick     | 点击图片时触发           | <code>(e: MouseEvent&lt;HTMLImageElement&gt;) =&gt; void</code> | -       |
| onLoad      | 图片加载完毕时触发       | <code>(e: MouseEvent&lt;HTMLImageElement&gt;) =&gt; void</code> | -       |
| onError     | 图片加载失败时触发       | <code>(e: MouseEvent&lt;HTMLImageElement&gt;) =&gt; void</code> | -       |</p></div></React.Fragment>
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }